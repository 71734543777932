@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url(https://cdnjs.cloudflare.com/ajax/libs/MaterialDesign-Webfont/5.3.45/css/materialdesignicons.min.css);
@import url('https://fonts.googleapis.com/css2?family=Lexend+Deca:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100..900;1,100..900&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');

body {
    margin: 0;
    font-family: "Lexend Deca", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: neutralscale;
    overflow: hidden;


}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.container-sidebar {
    margin-right: auto;
    margin-left: auto;
    padding-left: 16rem;
    width: 100%;
}

.z-1000 {
    z-index: 1000;
}

.container-sidebar-small {
    margin-right: auto;
    margin-left: auto;
    padding-left: 7rem;
    width: 100%;
}

@media (max-width:720px) {
    .container-sidebar {
        margin-right: auto;
        margin-left: auto;
        max-width: 100%;
    }

    .container-sidebar-small {
        margin-right: auto;
        margin-left: auto;
        width: 100%;
    }

}




.theme {
    --transDur: 0.3s;

    display: flex;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
}

.theme__fill,
.theme__icon {
    transition: transform var(--transDur) ease-in-out;
}

.theme__fill {
    background-color: var(--bg);
    display: block;
    mix-blend-mode: difference;
    position: fixed;
    inset: 0;
    height: 100%;
    transform: translateX(-100%);
}

.theme__icon,
.theme__toggle {
    z-index: 1;
}

.theme__icon,
.theme__icon-part {
    position: absolute;
}

.theme__icon {
    display: block;
    top: 0.77em;
    left: 0.8em;
    width: 0.4em;
    height: 0.4em;
}

.theme__icon-part {
    border-radius: 50%;
    box-shadow: 0.4em -0.4em 0 0.5em hsl(0, 0%, 100%) inset;
    top: calc(50% - 0.5em);
    left: calc(50% - 0.5em);
    width: 1em;
    height: 1em;
    transition:
        box-shadow var(--transDur) ease-in-out,
        opacity var(--transDur) ease-in-out,
        transform var(--transDur) ease-in-out;
    transform: scale(0.5);
}

.theme__icon-part~.theme__icon-part {
    background-color: hsl(0, 0%, 100%);
    border-radius: 0.05em;
    box-shadow: none;
    top: 50%;
    left: calc(50% - 0.05em);
    transform: rotate(0deg) translateY(0.3em);
    transform-origin: 50% 0;
    width: 0.1em;
    height: 0.2em;
}

.theme__icon-part:nth-child(3) {
    transform: rotate(45deg) translateY(0.3em);
}

.theme__icon-part:nth-child(4) {
    transform: rotate(90deg) translateY(0.3em);
}

.theme__icon-part:nth-child(5) {
    transform: rotate(135deg) translateY(0.3em);
}

.theme__icon-part:nth-child(6) {
    transform: rotate(180deg) translateY(0.3em);
}

.theme__icon-part:nth-child(7) {
    transform: rotate(225deg) translateY(0.3em);
}

.theme__icon-part:nth-child(8) {
    transform: rotate(270deg) translateY(0.3em);
}

.theme__icon-part:nth-child(9) {
    transform: rotate(315deg) translateY(0.3em);
}

.theme__label,
.theme__toggle,
.theme__toggle-wrap {
    position: relative;
}

.theme__toggle,
.theme__toggle:before {
    display: block;
}

.theme__toggle {
    background-color: hsl(48, 90%, 85%);
    border-radius: 25% / 50%;
    box-shadow: 0 0 0 0.125em var(--green-600T);
    padding: 0.25em;
    width: 4em;
    height: 2em;
    -webkit-appearance: none;
    appearance: none;
    transition:
        background-color var(--transDur) ease-in-out,
        box-shadow 0.15s ease-in-out,
        transform var(--transDur) ease-in-out;
}

.theme__toggle:before {
    background-color: hsl(48, 90%, 55%);
    border-radius: 50%;
    content: "";
    width: 1.5em;
    height: 1.5em;
    transition:
        background-color var(--transDur) ease-in-out,
        transform var(--transDur) ease-in-out;
}

.theme__toggle:focus {
    box-shadow: 0 0 0 0.125em var(--green-600);
    outline: transparent;
}

/* Checked */
.theme__toggle:checked {
    background-color: hsl(198, 90%, 15%);
}

.theme__toggle:checked:before,
.theme__toggle:checked~.theme__icon {
    transform: translateX(2em);
}

.theme__toggle:checked:before {
    background-color: hsl(198, 90%, 55%);
}


.theme__toggle:checked~.theme__icon .theme__icon-part:nth-child(1) {
    box-shadow: 0.2em -0.2em 0 0.2em hsl(0, 0%, 100%) inset;
    transform: scale(1);
}

.theme__toggle:checked~.theme__icon .theme__icon-part~.theme__icon-part {
    opacity: 0;
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
    /* width of the scrollbar */
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
    /* color of the track */
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aba9a9;
    /* color of the handle */
    border-radius: 6px;
    /* roundness of the handle */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #8f8f8f;
    /* color of the handle on hover */
}



@supports selector(:focus-visible) {
    .theme__toggle:focus {
        box-shadow: 0 0 0 0.125em var(--green-600T);
    }

    .theme__toggle:focus-visible {
        box-shadow: 0 0 0 0.125em var(--green-600);
    }
}


